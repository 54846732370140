import request from '@/common/utils/request'
import Vue from "vue";

export function getVisitSiteList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/get-visit-site-list',
    method: 'post',
    data
  })
}

export function addVisitSite(data){
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/add-visit-site',
    method: 'post',
    data
  })
}

export function updateVisitSite(data){
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/update-visit-site',
    method: 'post',
    data
  })
}

export function deleteVisitSite(data){
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/delete-visit-site',
    method: 'post',
    data
  })
}
